<template>
  <ion-page>
    <ion-content fullscreen>
      <ion-list lines="none">
        <ion-item>
          <ion-label>{{$t('specialMappingText1')}}</ion-label>
        </ion-item>
        <ion-radio-group :value="special" @ionChange="handleSpecial">
          <ion-item v-for="(item, index) in noteSpecialInfo" :key="index">
            <ion-label>{{ item.special_info_name }}</ion-label>
            <ion-note slot="end">{{ item.add_cmp_amt }}</ion-note>
            <ion-radio slot="start" :value="item"></ion-radio>
          </ion-item>
        </ion-radio-group>
        
      </ion-list>
      <ion-button color="primary" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonPage,
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonRadioGroup,
  IonRadio,
  useIonRouter
} from '@ionic/vue'
import { defineComponent, onMounted, ref } from 'vue'
// import { useStore } from 'vuex'
import { useRoute/* , useRouter */ } from 'vue-router'
import { getData, listData, updateData } from '@/api/board/common'

export default defineComponent({
  name: 'special-mapping',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonNote, 
    IonRadioGroup,
    IonRadio
  },
  setup() {
    const route = useRoute()
    const router = useIonRouter()
    // const store = useStore()
    let special = ref(undefined)
    let noteSpecialInfo = ref([])
    let brandName = ref(undefined)
    let com_main_num = ref(0)
    let noteCategoryBrand = ref({})
    let payload = ref({
      num: '',
      special: '',
      special_num: '',
      total_price: 0
    })

    onMounted(async () => {
      await getComMainNum()
      await getOrderdetail()
      await getSpecialinfo()
      await getOrderTotalPrice()
    })

    const getOrderTotalPrice = async () => {
      const apiUrl = '/api/notebook/order/totalprice/'
      payload.value.total_price = await getData(apiUrl, route.params.num)
    }

    const getComMainNum = async () => {
      const memId = Cookies.get('memId')
      let apiUrl = `/api/notebook/order/mainnum/${memId}`
      com_main_num.value = await listData(apiUrl)
    }

    const getSpecialinfo = async () => {
      const comNum = com_main_num.value
      let apiUrl = `/api/notebook/specialinfo/${comNum}`
      noteSpecialInfo.value = await listData(apiUrl)
      noteSpecialInfo.value = noteSpecialInfo.value.filter(e => e.cate_name === noteCategoryBrand.value.cate_name)
      if (noteSpecialInfo.value.length === 0) {
        moveNext()
      }
    }

    const getCategoryDetail = async (num, target) => {
      const apiUrl = '/api/notebook/category-detail'
      await getData(apiUrl, num).then(response => target.value = response)
    }

    const getOrderdetail = async () => {
      let apiUrl = `/api/notebook/order/orderdetail/${Number(route.params.num)}`
      await listData(apiUrl).then(async (response) => {
        if (response.basic_num) {
          await getCategoryDetail(response.basic_num, noteCategoryBrand)
        }
      })
    }

    // const setTotalPrice = () => {
    //   Object.keys(payload.value).map(o => {
    //     if (o === 'total_price') {
    //       // TODO: 특판정보 금액 계산
    //       payload.value.total_price = Number(payload.value.total_price) + Number(payload.value.special)
    //     }
    //   })
    // }

    const handleSpecial = async (val) => {
      special.value = val.detail.value
      handleSpecialInfo(val.detail.value)
    }

    const handleSpecialInfo = async (item) => {
      payload.value.num = Number(route.params.num)
      payload.value.special = Number(item.add_cmp_amt)
      payload.value.special_num = Number(item.special_info_id)
    }

    const handleNext = () => {
      if (payload.value.num && payload.value.special_num) {
        // setTotalPrice()
        putSpecialInfo()
      } else {
        moveNext()
      }
    }

    const moveNext = () => {
      router.push({
        name: 'photos',
        params: {
          num: route.params.num
        }
      })
    }

    const putSpecialInfo = async () => {
      const apiUrl = `/api/notebook/order/specialinfo/`
      await updateData(apiUrl, payload.value).then(response => {
        if (response > 0) {
          moveNext()
        }
      })
    }

    return {
      special,
      payload,
      noteSpecialInfo,
      noteCategoryBrand,
      com_main_num,
      brandName,
      getOrderTotalPrice,
      getCategoryDetail,
      getOrderdetail,
      handleNext,
      handleSpecial,
      handleSpecialInfo
    }
  },
})
</script>

<style>

</style>